@import "./../../../assets/scss/variables";

.message-container .fa {
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 2em;
}
.message-container.alert-warning {
  border-color: #f2e0a8;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.message-container.alert-success {
  background-color: #3bb02e;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.message-container.alert-danger {
  background-color: #d62020;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding-top: 3px;
  padding-bottom: 3px;
}

.icon-edit-pencil {
  vertical-align: -3px;
  margin-right: 5px;
}

.subscriberName {
  cursor: pointer;
}

.olay {
  height: 100%;
  .default-color span {
    color: transparent;
  }
}

.loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.678);
  z-index: 2000;
}
#loading {
  margin: auto;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
