/* import custom variables and mixin */

@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.login-container {
  background: $white;
  width: 500px;
  padding: 30px 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -200px;
  @include Box-Shadow(0px, 0px, 5px, 5px rgba(0, 0, 0, 0.1));
  @include border-radius(4px);
  .logo {
    padding: 0px 0 15px 0;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: solid 1px $input-border;
  }

  .logo-navbar {
    height: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .custom-btn {
    min-width: 100px;
  }
  .button-row {
    button {
      margin-left: 0;
    }
    .login-link-container {
      display: flex;
      align-items: center;
      a {
        margin-left: 20px;
      }
    }
  }
  .input-custom {
    .input-group-text {
      @include border-radius(25px 0 0 25px);
      width: 25px;
      i {
        margin: 0 4px;
      }
    }
  }
  .input-group-text {
    min-width: 46px;
    padding:0;
    position: relative;
    i{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn-seperator-text {
    padding: 15px;
    width: 100%;
    text-align: center;
  }

  .azureLogin {
    width: 100%;
    margin: 5px;
    padding: 8px 20px;
    text-transform: uppercase;
    border-radius: 25px;
    background-color: $brand-color;
    color: $white;
    border: 0px;
    -webkit-highlight: none;
    outline: none;
    cursor: pointer;
    i {
      margin-right: 15px;
    }
  }

  .login-or {
    position: relative;
    padding: 20px;

    .span-or {
      position: absolute;
      width: 50px;
      left: 50%;
      top: 0px;
      margin-top: 22px;
      background: #fff;
      margin-left: -25px;
      text-align: center;
    }
  }
}
