@import "./../../assets/scss/variables";

.conversationTable {
  margin: 20px;
  padding: 0px;
  min-height: 60%;
  .content-wrapper {
    border: solid 0.0625rem #dadada;
    border-radius: 0 4px 4px 4px;
    // min-height: 80%;
  }
  .pagination-navbar {
    float: right;

    // padding: 20px;
    margin: 20px;
    .pagination-arrow-botton-wrapper {
      display: inline-flex;
      //  margin-top: 5px;
      > div {
        //  margin: 10px;
        // padding: 3px;
        margin: 0px 5px;
        // border: 1px solid gray;
        border-radius: 5px;
        cursor: pointer;

        // display: grid;
        // place-items: center;
        > i {
          font-size: 1.4rem;
          margin-top: 5px;
        }
      }
    }
    span {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  table {
    border-collapse: collapse;
    margin: 25px 0px;
    width: 100%;
    max-width: 100%;
    // white-space: nowrap;
    table-layout: auto;

    //    table-layout: fixed;
    overflow-wrap: break-word;

    thead {
      border-bottom: 2px solid $brand-color;

      tr {
        th {
          color: #444444;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.6px;
          line-height: 14px;
          text-transform: uppercase;
          padding: 8px 12px;

          &:nth-child(2) {
            padding-left: 0px;
          }
        }
      }
    }
    tbody {
      .selected-row {
        font-weight: 600;
        background-color: #f6f7fa;
      }
      tr {
        height: auto;
        cursor: pointer;
        td {
          word-wrap: break-word; /* All browsers since IE 5.5+ */
          overflow-wrap: break-word;
          color: #333333;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          width: auto !important;
          padding: 16px 8px;
          &:nth-child(2) {
            padding-left: 0px;
          }
        }

        .icon-arrow {
          max-width: auto;
          margin: 0px auto;
          cursor: pointer;
          height: 100%;
          //margin-top: 7px;
          //   background-color: pink;
        }
      }
      .expanded {
        // font-weight: 900;
        background-color: #f6f7fa;
        border-bottom: 1px solid $table-border;
        td {
          padding: 20px 20px 20px 30px;
          .message-cards {
            // display: grid;
            // grid-template-columns: 1fr;
            //  max-width: 500px;
            height: auto;
            //  background-color: pink;
            width: 100%;
            // overflow: hidden;

            .message-card {
              background-color: #ffffff;
              padding: 20px;
              margin: 5px 0px 10px 0px;
              border: 1px solid $table-border;
              border-radius: 5px;
              //  width: 100%;
              //  overflow-wrap: break-word;

              .message-header {
                display: flex;
                justify-content: space-between;

                .senderFullName {
                  p {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600 !important;
                    letter-spacing: 0;
                    line-height: 24px;
                  }
                }
              }

              .createdTimestamp {
                color: #7a7e81;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
              }
              .message-body {
                color: #333333;
                font-size: 14px;
                //  font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                width: 100%;
                // max-width: 80%;

                p {
                  width: inherit; /* width: 100px; */
                  white-space: pre-wrap;
                }
                img {
                  max-width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }

  .email-attachments-wrapper {
    margin-top: 10px;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 275px));
    grid-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    // overflow-wrap: break-word;

    .email-attachment {
      width: 275px;
      height: 100px;
      .top {
        display: grid;
        grid-template-columns: 0.9fr 5fr;
        justify-content: center;
        align-items: center;
        border: 1px solid #bbbdbf;
        padding: 10px;
        margin-bottom: 10px;
        .file-desc {
          grid-column-start: 2;
          grid-column-end: 4;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          p {
            color: $color-text;
            line-height: normal;
            font-size: 14px;
            margin-bottom: 0.25rem;
          }
        }
      }
      .buttom {
        cursor: pointer;
        p {
          color: $color-blue;
          font-size: 14px;
        }
      }
    }
  }

  .action-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    .btn {
      &:focus {
        box-shadow: none;
      }
    }
    .reply-btn-wrapper {
      background-color: lightblue;
      display: contents;
      flex-direction: column;
    }
    .btn-reply {
      width: 10%;
      height: 100%;
      font-size: 14px;
      .icon-corner-up-left {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .move-message-container {
      height: 100%;
      display: flex;
      //max-width: 150px;
      align-items: center;
      margin-left: 8px;
      .move-message-label {
        margin: 0px;
        margin-left: px;
      }
    }
  }
}

.load-more-btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 5%;
}

.new-message-btn-wrapper {
  margin: 15px 0px 0px 15px;
}

.tab-options {
  display: flex;
  //border-bottom: 1px solid $table-border;
  margin-top: 25px;
  .tab-action {
    cursor: pointer;
    font-weight: 700;
    padding: 10px;
    margin-right: 0.125rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid $table-border;
    border-right: 1px solid $table-border;
    border-left: 1px solid $table-border;
    padding: 0.5rem 1rem;
    &:last-child {
      border-top-right-radius: 5px;
    }
    &:hover {
      border-color: $brand-color;
    }
    &.selected {
      color: white;
      background-color: $brand-color;
      border-color: $brand-color;
    }
  }
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 16px;
  resize: vertical;
  &:hover,
  &:focus {
    border-left: none;
    border-right: none;
    outline: none;
  }
}
